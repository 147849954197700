<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline indigo--text text-darken-4">Search for Student ID</span>
                </v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="model"
                        :items="fields"
                        :loading="isLoading"
                        :search-input.sync="search"
                        color="indigo--text text-darken-4"
                        hide-no-data
                        hide-selected
                        item-text="name"
                        label="Student Name"
                        placeholder="Start typing to Search"
                        prepend-icon="mdi-database-search"
                        return-object
                        clearable
                    ></v-autocomplete>
                </v-card-text>
                <v-expand-transition>
                    <v-card
                        max-width="400"
                        class="mx-auto"
                    >
                        <v-list
                            v-if="model"
                            class="indigo lighten-3 pa-4"
                        >   
                            <v-list-item-content>
                                <v-list-item-title class="text-button">Student ID:  {{ model.id }}</v-list-item-title>
                                <v-list-item-title class="text-button">Student Name: {{ model.name }}</v-list-item-title>
                            </v-list-item-content>                        
                        </v-list>
                    </v-card>
                </v-expand-transition>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="indigo darken-4"
                        text
                        @click="closeDialog(false)"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name:'QueryId',
    data: () => ({
        discriptionLimit: 60,
        entries: [],
        students: [],
        isLoading: false,
        model: null,        
        search: null
    }),
    mounted: function(){
        this.loadStudents();
    },
    computed: {
        ...mapGetters({
            dialog: 'auth/getDialog',
        }),       
        fields (){            
            this.students.forEach(student => {
                let studentName = student.first_name + ' ' + student.last_name;
                this.entries.push({id: student.id, name: studentName})
            })
            return this.entries;
        }
    },    
    methods: {
        ...mapMutations({
            closeDialog: 'auth/setDialog'
        }),
        ...mapActions({
            getStudents: 'auth/getStudents'
        }),
        loadStudents(){
            this.getStudents()
                .then(response => { 
                    //console.log(response)                  
                    this.students = response.data;
                })
        },
    }

}
</script>